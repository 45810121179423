
import { Component, Prop, Vue } from 'vue-property-decorator'
import { apigoodsServiceGuaranteelists } from '@/api/goods'
@Component
export default class AddSales extends Vue {
    @Prop() value: any
    @Prop() isGatherGoods: any
    goodsServiceGuaranteelists: any = []
    created() {
        apigoodsServiceGuaranteelists().then(res => {
            this.goodsServiceGuaranteelists = res
        })
    }
}
