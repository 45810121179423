
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import PopoverInput from '@/components/popover-input.vue'
import { flatten } from '@/utils/util'
@Component({
  components: {
    MaterialSelect,
    PopoverInput
  }
})
export default class Specification extends Vue {
    $refs!: { materialSelect: any }
    @Prop() value: any
    tableDataIndex = 0
    fileList = []
    tableData: any[] = []
    selectData: any[] = []
    get sepcItem () {
      return this.value.spec_value
    }

    get disabledBatchBtn () {
      return !this.selectData.length
    }

    @Watch('value.spec_value_list')
    defaultDataChange (val: any[]) {
      this.tableData = val
    }

    @Watch('sepcItem', { deep: true, immediate: true })
    sepcItemChange (val: any) {
      this.setTableData()
    }

    @Watch('tableData', { deep: true })
    tableDataChange (val: any) {
      this.value.spec_value_list = val
    }

    // 添加规格项
    addSpecItem () {
      if (this.sepcItem.length >= 3) {
        return
      }
      this.sepcItem.push({
        has_image: false,
        id: '',
        name: '',
        spec_list: [
          {
            id: '',
            value: '',
            image: ''
          }
        ]
      })
    }

    // 移除规格项
    removeSpecItem (index: number) {
      if (this.sepcItem.length <= 1) {
        return this.$message.error('至少有一个规格项')
      }
      this.sepcItem.splice(index, 1)
    }

    // 添加规格值
    addSpecValue (index: number) {
      this.sepcItem[index].spec_list.push({
        id: '',
        value: '',
        image: ''
      })
    }

    // 移除规格值
    removeSpecValue (index: number, sindex: number) {
      this.sepcItem[index].spec_list.splice(sindex, 1)
    }

    addImage (i: number, v: boolean) {
      this.sepcItem.forEach((item: any, index: number) => {
        item.has_image = false
        if (i == index) {
          item.has_image = v
        }
        item.spec_list.forEach((sitem: any) => {
          sitem.image = ''
        })
      })
      this.tableData.forEach(item => {
        item.image = ''
      })
    }

    checkValue (index: number, sindex: number) {
      const value = this.sepcItem[index].spec_list[sindex].value
      this.sepcItem[index].spec_list.forEach((item: any, idx: number) => {
        if (item.value && sindex != idx && item.value == value) {
          this.sepcItem[index].spec_list[sindex].value = ''
          this.$message({
            message: '已存在相同规格值',
            type: 'warning'
          })
        }
      })
    }

    selectDataChange (value: any[]) {
      this.selectData = value.map(item => item.ids)
    }

    batchSetting (value: string, fields: string) {
      this.tableData.forEach(item => {
        if (this.selectData.includes(item.ids)) {
          item[fields] != undefined && (item[fields] = value)
        }
      })
    }

    // 设置字段名称
    setFields (prev: any, next: any) {
      let valueArr: any[] = [prev, next]
      valueArr = valueArr.filter(item => item.value !== undefined)
      const ids = flatten(valueArr.map(item => item.ids)).join()
      const value = flatten(valueArr.map(item => item.value))
      return {
        id: prev.id ? prev.id : '',
        ids: ids,
        value,
        spec_value_str: value.join(),
        image: prev.image ? prev.image : next.image,
        sell_price: prev.sell_price ? prev.sell_price : '',
        lineation_price: prev.lineation_price ? prev.lineation_price : '',
        cost_price: prev.cost_price ? prev.cost_price : '',
        stock: prev.stock ? prev.stock : '',
        volume: prev.volume ? prev.volume : '',
        weight: prev.weight ? prev.weight : '',
        bar_code: prev.bar_code ? prev.bar_code : '',
        integral: prev.integral ? prev.integral : '',
        redpacket: prev.redpacket ? prev.redpacket : ''
      }
    }

    // 通过规格项和规格值得到一个表格data
    getTableData (arr: any[]) {
      arr = JSON.parse(JSON.stringify(arr))
      return arr.reduce(
        (prev, next) => {
          const newArr: any[] = []
          for (let i = 0; i < prev.length; i++) {
            if (!next.length) {
              newArr.push(this.setFields(prev[i], {}))
            }
            for (let j = 0; j < next.length; j++) {
              next[j].ids = j
              newArr.push(this.setFields(prev[i], next[j]))
            }
          }
          return newArr
        },
        [{}]
      )
    }

    setTableData () {
      const { tableData, sepcItem } = this
      const specList = sepcItem.map((item: any) => item.spec_list)
      const newData = this.getTableData(specList)
      const rawData = JSON.parse(JSON.stringify(tableData))
      const rawObject: any = {}
      rawData.forEach((item: any) => {
        if (item.spec_value_str !== undefined) {
          rawObject[item.spec_value_str] = item
        }
      })
      this.tableData = newData.map((item: any) =>
        rawObject[item.spec_value_str]
          ? {
            ...rawObject[item.spec_value_str],
            value: item.value,
            ids: item.ids,
            image: item.image || rawObject[item.spec_value_str].image
          }
          : item
      )
    }

    addSpecImage (index: number) {
      this.tableDataIndex = index
      this.$refs.materialSelect.showDialog()
    }

    changeSpecImage (value: string) {
      this.tableData[this.tableDataIndex].image = value
    }

    removeSpecImage (index: number) {
      this.tableData[index].image = ''
    }
}
