
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import {
  addGoodsDeliveryTemplate,
  GoodsDeliveryTemplate,
  editGoodsDeliveryTemplate,
  delGoodsDeliveryTemplate
} from '@/api/goods'
import { Message } from 'element-ui'

@Component({
  components: {
    LsDialog
  }
})
export default class AddLogistics extends Vue {
    $refs!: { dialog: any; dialogtem: any }

    @Prop() value: any
    @Prop({ default: () => ({}) }) lists: any
    templateForm: any = {
      name: '',
      type: 0,
      content: '',
      content1: []
    }

    templateEdit: any = {
      name: '',
      type: 0,
      content: '',
      content1: []
    }

    templateLists: any = []
    Reflash = true
    get freightList () {
      return this.lists.freight_list || []
    }

    get logisticsList () {
      return this.lists.logistics_list || []
    }

    handleChange (val: string) {
      this.value.delivery_content = val.trim()
    }

    handleAdd () {
      this.templateForm.content1.push({ name: '', content: '' })
    }

    handleDel (delval: number) {
      this.templateForm.content1 = this.templateForm.content1.filter((i: any, index: number) => {
        return index != delval
      })
    }

    handleEditadd () {
      this.templateEdit.content1.push({ name: '', content: '' })
    }

    handleEditdel (delval: number) {
      this.templateEdit.content1 = this.templateEdit.content1.filter((i: any, index: number) => {
        return index != delval
      })
    }

    handleConfirm () {
      const confirme = this.templateForm.content1.some((i: any) => {
        return i.name == '' || i.content == ''
      })
      if (confirme) {
        return Message({ type: 'error', message: '请完善发货内容' })
      }
      addGoodsDeliveryTemplate(this.templateForm).then(res => {
        this.templateForm = {
          name: '',
          type: 0,
          content: '',
          content1: []
        }
        this.$refs.dialog.close()
      })
    }

    created () {
      GoodsDeliveryTemplate().then(res => {
        this.templateLists = res.lists
      })
    }

    handleReflash () {
      this.Reflash = false
      GoodsDeliveryTemplate().then(res => {
        this.templateLists = res.lists
        this.Reflash = true
      })
    }

    handleTemdel (id: number) {
      delGoodsDeliveryTemplate({ id }).then(res => {})
    }

    handleEditopen (id: number) {
      this.templateEdit = this.templateLists.find((item: any) => {
        return item.id == id
      })
    }

    handleConfirmtem (index: number) {
      const confirme = this.templateEdit.content1.some((i: any) => {
        return i.name == '' || i.content == ''
      })
      if (confirme) {
        return Message({ type: 'error', message: '请完善发货内容' })
      }
      editGoodsDeliveryTemplate(this.templateEdit)
      this.$refs.dialogtem[index].close()
    }
}
